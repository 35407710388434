"use strict";
exports.__esModule = true;
exports.stepPropsThatAffectViewerRendering = exports.flowPropsThatAffectViewerRendering = exports.DEFAULT_TEAM_TRIAL_LENGTH_IN_DAYS = void 0;
exports.DEFAULT_TEAM_TRIAL_LENGTH_IN_DAYS = 14;
exports.flowPropsThatAffectViewerRendering = [
    'aspectRatio',
    'belongsToTeam',
    'font',
];
exports.stepPropsThatAffectViewerRendering = [
    'blur',
    'buttonColor',
    'buttonText',
    'buttonTextColor',
    'duration',
    'endTimeFrac',
    'hotspots',
    'muted',
    'panAndZoom',
    'paths',
    'playbackRate',
    'size',
    'startTimeFrac',
    'streamUrl',
    'subtitle',
    'theme',
    'title',
    'url',
    'videoThumbnailUrl',
];
